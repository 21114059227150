import rules from '@/rules'

export default {
  clientType: null,
  client_name: '',
  demo: false,
  epost_active: false,
  error: null,
  finapiurl: null,
  layout: 'base-layout',
  loggedIn: false,
  loginError: false,
  menu: false,
  availableNodes: [],
  nodeId: '',
  nodeCustomerID: '',
  selectedNode: '',
  reminderactive: false,
  roles: {},
  rules,
  settingsFormValid: false,
  skipNotificationSubscription: false,
  snackbar: false,
  snackbarAction: null,
  snackbarLink: null,
  snackbarLinkTitle: null,
  snackbarText: '',
  snackbarType: 'success',
  sticky: false,
  subtitle: '',
  title: 'sanadeus',
  titlePrefix: config.NODE_ENV === 'development' ? '[DEV]' : '',
  token: null,
  wfStatus: null,
}


import axios from 'axios'
import router from '../router'

export default {
    async login({commit, dispatch}, credentials) {
        try {
            const {data} = await axios.post(
                `${config.VUE_APP_API_ENDPOINT}/api/auth/client/login`,
                {
                    email: credentials.email,
                    password: credentials.password,
                },
            )
            if (data) {
                if (credentials.target) {
                    const resolvedRoute = router.resolve(credentials.target)
                    if (resolvedRoute.route.query.node) data.nodeFromRoute = resolvedRoute.route.query.node
                }
                dispatch('setClient', data)
                await router.push(credentials.target || '/')
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                commit('SET_LOGIN_ERROR', true)
            } else {
                throw error
            }
        }
    },
    async refresh({dispatch}, target) {
        try {
            const {data} = await axios.post(
                `${config.VUE_APP_API_ENDPOINT}/api/auth/client/refresh`,
                null,
                {withCredentials: true},
            )
            if (data) {
                dispatch('setClient', {...data, nodeFromRoute: target.query.node})
                if (
                    data.newpasswordrequired &&
                    !target.startsWith('/newpasswordrequired')
                ) {
                    await router.push({
                        name: 'newpasswordrequired',
                        query: {redirect: target.fullPath},
                    })
                } else {
                    const node = () => {
                        if (!data.availableNodes || data.availableNodes.length === 1) return data.node.id
                        return target.query.node || data.node.id
                    }
                    target.query.node = node()
                    await router.push(target)
                }
            }
        } catch (err) {
            await router.push({
                name: 'login',
                query: {redirect: target.fullPath},
            })
        }
    },
    async logout({commit}) {
        await axios.post(
            `${config.VUE_APP_API_ENDPOINT}/api/auth/client/logout`,
            null,
            {
                withCredentials: true,
            },
        )
        commit('SET_LOGIN', false)
        await router.push({name: 'login'})
    },
    setClient({commit, getters}, data) {

        const getNodeIdForQuery = (data) => {
            if (!data.availableNodes || data.availableNodes.length === 1) return data.node.id
            return data.nodeFromRoute || data.availableNodes[0].id
        }

        const getCustomerId = (data) => {
            if (!data.availableNodes || data.availableNodes.length === 1) return data.node.customerID
            if (!data.nodeFromRoute) return data.availableNodes[0].customerID
            return data.availableNodes.find(node => node.id === data.nodeFromRoute).customerID || data.availableNodes[0].customerID
        }

        commit('SET_DEMO', data.demo_node)
        commit('SET_LOGIN_ERROR', false)
        commit('SET_ERROR', null)
        commit('SET_SNACKBAR', false)
        commit('SET_LOGIN', true)
        commit('SET_ROLES', data.roles)
        commit('SET_EPOST_ACTIVE', data.epost_active_node)
        commit('SET_CLIENT_TYPE', data.client_type)
        commit('SET_CLIENT_NAME', data.client_name)
        commit('SET_NEW_PASSWORD_REQUIRED', data.newpasswordrequired)
        commit('SET_LAYOUT', 'base-layout')
        commit('SET_NODE_ID', data.node.id)
        commit('SET_AVAILABLE_NODES', data.availableNodes)
        commit('SET_NODE_CUSTOMER_ID', getCustomerId(data))
        commit('SET_SELECTED_NODE', getNodeIdForQuery(data))
        if (getters.is_app) {
            const {ipcRenderer} = window.electron
            ipcRenderer.send('watchFolder', data.pad_folder)
        }
    },

    showMenu({commit}) {
        commit('SET_MENU', true)
    },

    hideMenu({commit}) {
        commit('SET_MENU', false)
    },

    setSticky({commit}, value) {
        commit('SET_STICKY', value)
    },

    pauseNotificationSubscription({commit}) {
        commit('SET_SKIP_NOTIFICATION_SUBSCRIPTION', true)
    },

    resumeNotificationSubscription({commit}) {
        commit('SET_SKIP_NOTIFICATION_SUBSCRIPTION', false)
    },

    setSnackbarText({commit}, {text, actionFunc, type, link, linkTitle}) {
        commit('SET_SNACKBAR_TEXT', {
            actionFunc,
            text,
            type,
            link,
            linkTitle,
        })
    },
}
